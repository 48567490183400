import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  CircularProgress,
  Autocomplete,
  TextField,
  Button,
  Grid
} from '@mui/material';
import axios from 'axios';
import { AlertProvider, AlertContext } from '../components/alerts/AlertContext';
import { DashboardProvider } from '../components/dashboard/DashboardContext';
import SymbolDetails from '../components/dashboard/SymbolDetails';
import TabPanel from '../components/dashboard/TabPanel';
import RightPanel from '../components/dashboard/RightPanel';
import { getUserIdFromToken } from '../utils/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = {
  listItem: {
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
  sectionHeader: {
    padding: '12px 16px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e0e0e0',
    fontWeight: 600,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  alertValue: {
    fontSize: '0.9rem',
    color: '#1976d2',
    fontWeight: 500,
  },
  searchField: {
    margin: '12px 16px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff'
    }
  },
  screenerButton: {
    fontSize: '0.875rem',
    backgroundColor: '#2e7d32',
    color: 'white',
    padding: '6px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#1b5e20',
    }
  }
};

const AlertsContent = () => {
  const {
    selectedContent,
    setSelectedContent,
    symbolDetails,
    setSymbolDetails,
    setLivePrices,
    enabledAccounts,
    fetchEnabledAccounts,
    livePrices
  } = useContext(AlertContext);

  const [alerts60d, setAlerts60d] = useState([]);
  const [alerts5d, setAlerts5d] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [allSymbols, setAllSymbols] = useState([]);
  const [filteredSymbols, setFilteredSymbols] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [newAlertsCount, setNewAlertsCount] = useState({ alerts60d: 0, alerts5d: 0 });
  const [isLoadingPositions, setIsLoadingPositions] = useState(false);
  const [cashPage, setCashPage] = useState(1);
  const [fnoPage, setFnoPage] = useState(1);
  const [cashPositions, setCashPositions] = useState([]);
  const [fnoPositions, setFnoPositions] = useState([]);
  const [isCashLoading, setIsCashLoading] = useState(false);
  const [isFnoLoading, setIsFnoLoading] = useState(false);
  const [positionsError, setPositionsError] = useState({ cash: null, fno: null });
  const [noPositionsMessage, setNoPositionsMessage] = useState({ cash: '', fno: '' });
  const [cashHasMore, setCashHasMore] = useState(true);
  const [fnoHasMore, setFnoHasMore] = useState(true);
  const [eventCalendarItems, setEventCalendarItems] = useState([]);
  const [newsFilter, setNewsFilter] = useState('ALL');
  const [portfolioSymbols, setPortfolioSymbols] = useState([]);
  const [percentageChanges, setPercentageChanges] = useState({});

  const seenIds = useRef({ alerts60d: new Set(), alerts5d: new Set() });
  const autocompleteRef = useRef(null);
  const alerts60dRef = useRef(null);
  const alerts5dRef = useRef(null);

  const formatDateTime = (dateString, dateOnly = false) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', dateOnly ? {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    } : {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
  };

  const formatIndianNumber = (num, withDecimals = false) => {
    if (!num) return '0';
    const formatted = num.toLocaleString('en-IN', {
      maximumFractionDigits: withDecimals ? 2 : 0,
      minimumFractionDigits: withDecimals ? 2 : 0
    });
    return formatted;
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    window.location.href = '/login';
  };

  const loadMoreCash = async () => {
    if (!isCashLoading && cashHasMore) {
      setIsCashLoading(true);
      try {
        const token = localStorage.getItem('userToken');
        if (!token) throw new Error('No authentication token found');

        const headers = { 'Authorization': `Bearer ${token}` };
        const nextPage = cashPage + 1;
        const endpoint = selectedSymbol 
          ? `${API_BASE_URL}/cash_positions_by_symbol/${selectedSymbol}`
          : `${API_BASE_URL}/all_cash_positions`;

        const response = await axios.get(endpoint, {
          headers,
          params: { page: nextPage }
        });

        const newPositions = response.data.data || [];
        setCashPositions(prev => [...prev, ...newPositions]);
        setCashHasMore(response.data.has_next || false);
        setCashPage(nextPage);
      } catch (error) {
        setPositionsError(prev => ({
          ...prev,
          cash: `Failed to load cash positions: ${error.message}`
        }));
      } finally {
        setIsCashLoading(false);
      }
    }
  };

  const loadMoreFno = async () => {
    if (!isFnoLoading && fnoHasMore) {
      setIsFnoLoading(true);
      try {
        const token = localStorage.getItem('userToken');
        if (!token) throw new Error('No authentication token found');

        const headers = { 'Authorization': `Bearer ${token}` };
        const nextPage = fnoPage + 1;
        const endpoint = selectedSymbol 
          ? `${API_BASE_URL}/fno_positions_by_symbol/${selectedSymbol}`
          : `${API_BASE_URL}/all_fno_positions`;

        const response = await axios.get(endpoint, {
          headers,
          params: { page: nextPage }
        });

        const newPositions = response.data.data || [];
        setFnoPositions(prev => [...prev, ...newPositions]);
        setFnoHasMore(response.data.has_next || false);
        setFnoPage(nextPage);
      } catch (error) {
        setPositionsError(prev => ({
          ...prev,
          fno: `Failed to load F&O positions: ${error.message}`
        }));
      } finally {
        setIsFnoLoading(false);
      }
    }
  };

  const compareAlerts = (newAlerts, type) => {
    const newOnes = newAlerts.filter(alert => !seenIds.current[type].has(alert.id));
    newAlerts.forEach(alert => seenIds.current[type].add(alert.id));
    return newOnes;
  };

  const fetchAlerts = async (isInitial = false) => {
    try {
      if (isInitial) {
        setIsInitialLoading(true);
        seenIds.current = { alerts60d: new Set(), alerts5d: new Set() };
      } else {
        setIsRefreshing(true);
      }

      const [response60d, response5d] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_w52_60d`),
        axios.get(`${API_BASE_URL}/get_w52_5d`)
      ]);

      if (isInitial) {
        response60d.data.forEach(alert => seenIds.current.alerts60d.add(alert.id));
        response5d.data.forEach(alert => seenIds.current.alerts5d.add(alert.id));
      } else {
        const newAlerts60d = compareAlerts(response60d.data, 'alerts60d');
        const newAlerts5d = compareAlerts(response5d.data, 'alerts5d');

        if (alerts60dRef.current?.scrollTop > 0 && newAlerts60d.length > 0) {
          setNewAlertsCount(prev => ({
            ...prev,
            alerts60d: newAlerts60d.length
          }));
        }
        if (alerts5dRef.current?.scrollTop > 0 && newAlerts5d.length > 0) {
          setNewAlertsCount(prev => ({
            ...prev,
            alerts5d: newAlerts5d.length
          }));
        }
      }

      setAlerts60d(response60d.data);
      setAlerts5d(response5d.data);
    } catch (error) {
      console.error('Error fetching alerts:', error);
      setError('Failed to fetch alerts');
    } finally {
      setIsInitialLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleScroll = (listRef, alertType) => {
    if (listRef.current && listRef.current.scrollTop === 0) {
      setNewAlertsCount(prev => ({
        ...prev,
        [alertType]: 0
      }));
    }
  };

  const handleSymbolSelect = async (symbol) => {
    try {
      setIsLoadingPositions(true);
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');

      setSelectedContent({ symbol, nse: symbol });
      
      // Reset positions and pagination
      setCashPositions([]);
      setFnoPositions([]);
      setCashPage(1);
      setFnoPage(1);
      setCashHasMore(true);
      setFnoHasMore(true);

      // Fetch positions using symbol-specific endpoints
      const [symbolResponse, cashResponse, fnoResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_symbol_details/${symbol}`, { 
          headers: { Authorization: `Bearer ${token}` } 
        }),
        axios.get(`${API_BASE_URL}/cash_positions_by_symbol/${symbol}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { page: 1 }
        }),
        axios.get(`${API_BASE_URL}/fno_positions_by_symbol/${symbol}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { page: 1 }
        })
      ]);

      setSymbolDetails(symbolResponse.data);
      setCashPositions(cashResponse.data.data || []);
      setFnoPositions(fnoResponse.data.data || []);
      setCashHasMore(cashResponse.data.has_next || false);
      setFnoHasMore(fnoResponse.data.has_next || false);

      const userId = getUserIdFromToken(token);

      if (!enabledAccounts || enabledAccounts.length === 0) {
        const headers = { 'Authorization': `Bearer ${token}` };
        const enabledAccountsResponse = await axios.get(
            `${API_BASE_URL}/user/enabled-accounts/${userId}`,
            { headers }
        );
        const accounts = enabledAccountsResponse.data.enabledAccounts || [];
        if (!accounts || accounts.length === 0) {
          setError('No accounts enabled for this user');
          return;
        }
      }

      const [priceResponse, percentageResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_live_price/${symbol}`),
        axios.get(`${API_BASE_URL}/percentage_change/${symbol}`)
      ]);

      setLivePrices(prev => ({
        ...prev,
        [symbol]: priceResponse.data.LTP
      }));
      
      setPercentageChanges(prev => ({
        ...prev,
        [symbol]: percentageResponse.data.percentage_change
      }));

      setSelectedSymbol(symbol);
    } catch (error) {
      console.error('Error fetching symbol data:', error);
      setError(error.response?.data?.error || error.message);
      setSelectedContent(null);
      setSymbolDetails(null);
      setSelectedSymbol(null);
    } finally {
      setIsLoadingPositions(false);
    }
  };

  const clearSelection = () => {
    setSelectedContent(null);
    setSymbolDetails(null);
    setSelectedSymbol(null);
    setSearchQuery('');
    setCashPage(1);
    setFnoPage(1);
    setCashPositions([]);
    setFnoPositions([]);
    if (autocompleteRef.current) {
      autocompleteRef.current.value = '';
    }
  };

  const renderAlertsList = (alerts, title, listRef, alertType) => (
      <Box height="100%" overflow="auto" ref={listRef} onScroll={() => handleScroll(listRef, alertType)}>
        <List>
          <ListItem sx={styles.sectionHeader}>
            <ListItemText primary={<strong>{title}</strong>} />
          </ListItem>
          {newAlertsCount[alertType] > 0 && (
              <ListItem
                  button
                  onClick={() => {
                    if (listRef.current) {
                      listRef.current.scrollTop = 0;
                      setNewAlertsCount(prev => ({ ...prev, [alertType]: 0 }));
                    }
                  }}
                  sx={{
                    bgcolor: '#1976d2',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#1565c0',
                    },
                    position: 'sticky',
                    top: '48px',
                    zIndex: 1,
                    justifyContent: 'center',
                  }}
              >
                <Typography variant="body2">
                  {newAlertsCount[alertType]} new alert{newAlertsCount[alertType] > 1 ? 's' : ''} available
                </Typography>
              </ListItem>
          )}
          {isInitialLoading ? (
              <Box p={2} display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
          ) : alerts.length === 0 ? (
              <Box p={2} textAlign="center">
                <Typography color="text.secondary">No alerts available</Typography>
              </Box>
          ) : (
              alerts.map((alert, index) => (
                  <ListItem
                      button
                      key={`${alert.Company}-${index}`}
                      onClick={() => handleSymbolSelect(alert.Company)}
                      sx={styles.listItem}
                  >
                    <ListItemText
                        primary={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body1">{alert.Company}</Typography>
                            <Typography sx={styles.alertValue}>
                              ₹{alert['52W High']} ({alert['52W High Date']})
                            </Typography>
                          </Box>
                        }
                        secondary={
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="body2" color="text.secondary">
                              All Time High: ₹{alert['All Time High']}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {new Date(alert['Last Updated']).toLocaleTimeString('en-GB', { timeZone: 'GMT' })}
                            </Typography>
                          </Box>
                        }
                    />
                  </ListItem>
              ))
          )}
        </List>
      </Box>
  );

  useEffect(() => {
    fetchAlerts(true);
    fetchEnabledAccounts();

    const interval = setInterval(() => fetchAlerts(false), 6000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fetchAllSymbols = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/all_symbols`);
        setAllSymbols(response.data.symbols);
        setFilteredSymbols(response.data.symbols);
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchAllSymbols();
  }, []);

  useEffect(() => {
    // Fetch initial event calendar data
    const fetchEventCalendar = async () => {

      // Format today's date as YYYY-MM-DD
      const today = new Date().toISOString().split('T')[0];
      try {
        const response = await axios.get(`${API_BASE_URL}/event-calendar?start_date=${today}`);
        setEventCalendarItems(response.data || []);
      } catch (error) {
        console.error('Error fetching event calendar:', error);
      }
    };

    // Fetch portfolio symbols
    const fetchPortfolioSymbols = async () => {
      try {
        const token = localStorage.getItem('userToken');
        if (!token) return;

        const headers = { 'Authorization': `Bearer ${token}` };
        const response = await axios.get(`${API_BASE_URL}/get_cash_symbols`, { headers });
        setPortfolioSymbols(response.data.symbols || []);
      } catch (error) {
        console.error('Error fetching portfolio symbols:', error);
      }
    };

    fetchEventCalendar();
    fetchPortfolioSymbols();
  }, []);

  useEffect(() => {
    const refreshPrice = async () => {
      if (!selectedSymbol) return;

      try {
        const response = await axios.get(`${API_BASE_URL}/percentage_change/${selectedSymbol}`);
        setPercentageChanges(prev => ({
          ...prev,
          [selectedSymbol]: response.data.percentage_change
        }));
      } catch (error) {
        console.error('Error fetching percentage change:', error);
      }
    };

    // Initial fetch
    refreshPrice();
    // Refresh every 60 seconds
    const interval = setInterval(refreshPrice, 60000);
    return () => clearInterval(interval);
  }, [selectedSymbol]);

  return (
      <Box display="flex" height="100vh" overflow="hidden" bgcolor="#f0f2f5">
        {/* Left Column - Alerts */}
        <Box width="25%" borderRight={1} borderColor="divider" overflow="hidden" display="flex" flexDirection="column">
          <Box flex={1} minHeight={0}>
            {renderAlertsList(alerts60d, '52 Week High (60 days)', alerts60dRef, 'alerts60d')}
          </Box>
          <Box flex={1} minHeight={0}>
            {renderAlertsList(alerts5d, '52 Week High (5 days)', alerts5dRef, 'alerts5d')}
          </Box>
        </Box>

        {/* Middle Column - Symbol Details */}
        <Box flex={1} overflow="hidden" sx={{ backgroundColor: '#fff' }}>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Box sx={styles.searchField}>
                <Autocomplete
                    ref={autocompleteRef}
                    options={filteredSymbols}
                    value={selectedSymbol}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        handleSymbolSelect(newValue);
                      }
                    }}
                    onInputChange={(event, value) => {
                      setSearchQuery(value);
                      if (searchTimeout) clearTimeout(searchTimeout);
                      setSearchTimeout(setTimeout(() => {
                        const filtered = allSymbols.filter(symbol =>
                            symbol.toLowerCase().includes(value.toLowerCase())
                        );
                        setFilteredSymbols(filtered.slice(0, 100));
                      }, 300));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Search for a stock..."
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    )}
                />
              </Box>
            </Box>
            {selectedSymbol && (
                <Box mr={2}>
                  <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={clearSelection}
                      sx={{
                        textTransform: 'none',
                        fontSize: '0.875rem'
                      }}
                  >
                    Clear
                  </Button>
                </Box>
            )}
          </Box>
          {selectedSymbol && (
              <Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2} px={2}>
                  <Typography variant="h6">{selectedSymbol}</Typography>
                  <Box display="flex" alignItems="center" gap={2}>
                    {livePrices[selectedSymbol] && (
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="h6">
                          ₹{livePrices[selectedSymbol].toFixed(2)}
                        </Typography>
                        {percentageChanges[selectedSymbol] && (
                          <Typography 
                            variant="body1" 
                            color={percentageChanges[selectedSymbol] >= 0 ? '#2e7d32' : '#d32f2f'}
                            fontWeight={500}
                          >
                            ({percentageChanges[selectedSymbol] >= 0 ? '+' : ''}
                            {percentageChanges[selectedSymbol].toFixed(2)}%)
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Button
                      onClick={() => window.open(`https://www.screener.in/company/${selectedSymbol}/`)}
                      sx={styles.screenerButton}
                    >
                      View on Screener
                    </Button>
                  </Box>
                </Box>
                {isLoadingPositions ? (
                    <Box p={2} display="flex" justifyContent="center">
                      <CircularProgress size={24} />
                    </Box>
                ) : (
                    <>
                      <SymbolDetails />
                      <TabPanel />
                    </>
                )}
              </Box>
          )}
        </Box>

        {/* Right Column - Using RightPanel Component */}
        <RightPanel
            selectedContent={selectedContent}
            cashPositions={cashPositions}
            fnoPositions={fnoPositions}
            isCashLoading={isCashLoading}
            isFnoLoading={isFnoLoading}
            positionsError={positionsError}
            noPositionsMessage={noPositionsMessage}
            cashHasMore={cashHasMore}
            fnoHasMore={fnoHasMore}
            loadMoreCash={loadMoreCash}
            loadMoreFno={loadMoreFno}
            enabledAccounts={enabledAccounts}
            eventCalendarItems={eventCalendarItems}
            newsFilter={newsFilter}
            portfolioSymbols={portfolioSymbols}
            livePrices={livePrices}
            formatDateTime={formatDateTime}
            formatIndianNumber={formatIndianNumber}
            handleLogout={handleLogout}
        />
      </Box>
  );
};

const Alerts = () => (
    <DashboardProvider>
      <AlertProvider>
        <AlertsContent />
      </AlertProvider>
    </DashboardProvider>
);

export default Alerts;
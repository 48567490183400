import React from 'react';
import {
    Box,
    Typography,
    Button,
    TableRow,
    TableHead,
    Table,
    TableContainer,
    Paper,
    TableCell,
    TableBody
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import shortNameMapping from '../../helpers/short_name.json';

const styles = {
    tableContainer: {
        maxHeight: 'calc(100% - 48px)',
        '& .MuiTableCell-root': {
            padding: '8px 16px',
            fontSize: '0.875rem',
        },
        '& .MuiTableCell-head': {
            fontWeight: 700,
        },
        '& .MuiTableHead-root': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 1,
        }
    },
    positionHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 16px',
        backgroundColor: '#f8f9fa',
        borderBottom: '1px solid #e0e0e0',
        height: '48px',
        '& .title': {
            fontWeight: 600,
        }
    }
};

const RightPanel = ({
                        selectedContent,
                        cashPositions,
                        fnoPositions,
                        isCashLoading,
                        isFnoLoading,
                        positionsError,
                        noPositionsMessage,
                        cashHasMore,
                        fnoHasMore,
                        loadMoreCash,
                        loadMoreFno,
                        enabledAccounts,
                        eventCalendarItems,
                        newsFilter,
                        portfolioSymbols,
                        livePrices,
                        formatDateTime,
                        formatIndianNumber,
                        handleLogout
                    }) => {
    const calculatePositionValue = (position, type) => {
        const quantity = position.total_quantity_remaining || 0;
        const price = type === 'cash' ? position.LTP || 0 : position.UnderlyingLTP || 0;
        return quantity * price;
    };

    const extractUnderlyingSymbol = (symbol) => {
        const match = symbol.match(/^([A-Z]+)/);
        return match ? match[1] : symbol;
    };

    const renderPositions = (type) => {
        const positions = type === 'cash' ? cashPositions : fnoPositions;
        const isLoading = type === 'cash' ? isCashLoading : isFnoLoading;
        const error = positionsError[type];
        const noPositionsMsg = noPositionsMessage[type];
        const hasMore = type === 'cash' ? cashHasMore : fnoHasMore;

        if (isLoading && positions.length === 0) {
            return (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    Loading positions...
                </Box>
            );
        }

        if (error) {
            return (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'error.main'
                }}>
                    {error}
                </Box>
            );
        }

        if (!positions.length) {
            return (
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f5f5f5'
                }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        No Positions Found
                    </Typography>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {selectedContent
                            ? (noPositionsMsg || `No ${type.toUpperCase()} positions available for ${selectedContent.nse || selectedContent.symbol}`)
                            : 'Please select a news / announcement item or search for a stock to see positions'}
                    </Typography>
                </Box>
            );
        }

        return (
            <Box id={`${type}Scroll`} sx={{ height: '100%', overflow: 'auto' }}>
                <InfiniteScroll
                    dataLength={positions.length}
                    next={() => type === 'cash' ? loadMoreCash() : loadMoreFno()}
                    hasMore={hasMore}
                    loader={<Box p={2} textAlign="center">Loading more positions...</Box>}
                    scrollableTarget={`${type}Scroll`}
                >
                    <TableContainer component={Paper} sx={styles.tableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Symbol</TableCell>
                                    <TableCell align="right">Exposure</TableCell>
                                    <TableCell>Account Name</TableCell>
                                    <TableCell>Strategy</TableCell>
                                    <TableCell align="right">Qty</TableCell>
                                    <TableCell align="right">Avg Price</TableCell>
                                    {type === 'fno' && <TableCell align="right">Underlying Price</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {positions.map((position, index) => {
                                    const shortNameEntry = shortNameMapping.find(
                                        entry => entry.AccountName === position.AccountName
                                    );
                                    const shortName = shortNameEntry ? shortNameEntry.Account : position.AccountName;
                                    const symbol = type === 'fno' ? extractUnderlyingSymbol(position.symbol) : position.symbol;
                                    const ltp = livePrices[symbol] || position.LTP || position.UnderlyingLTP || 0;

                                    return (
                                        <TableRow key={`${type}-${position._id || index}`}>
                                            <TableCell>{position.symbol}</TableCell>
                                            <TableCell align="right">
                                                {formatIndianNumber(calculatePositionValue(position, type))}
                                            </TableCell>
                                            <TableCell>{shortName}</TableCell>
                                            <TableCell>{position.StgName}</TableCell>
                                            <TableCell align="right">{formatIndianNumber(position.total_quantity_remaining)}</TableCell>
                                            <TableCell align="right">
                                                {formatIndianNumber(position.average_price, true)}
                                            </TableCell>
                                            {type === 'fno' && (
                                                <TableCell align="right">
                                                    {formatIndianNumber(ltp, true)}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </InfiniteScroll>
            </Box>
        );
    };

    const renderMissingPositionsAccounts = () => {
        if (!selectedContent || !enabledAccounts.length) return null;

        if (cashPositions.length === 0) return null;

        const accountsWithCashPositions = new Set(cashPositions.map(pos => pos.AccountName));

        const missingAccounts = enabledAccounts.filter(account =>
            !accountsWithCashPositions.has(account.AccountName)
        );

        if (!missingAccounts.length) return null;

        return (
            <Box sx={{ padding: '16px', borderBottom: '1px solid #e0e0e0' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: 1 }}>
                    Cash Position not present in:
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                }}>
                    {missingAccounts.map((account) => (
                        <Box
                            key={account.Account}
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '0.875rem',
                            }}
                        >
                            {account.Account}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    const renderEventCalendar = () => {
        let filteredEvents = selectedContent
            ? eventCalendarItems.filter(event => event.symbol === (selectedContent.nse || selectedContent.symbol))
            : eventCalendarItems;

        if (newsFilter === 'POSITIONS') {
            filteredEvents = filteredEvents.filter(event => portfolioSymbols.includes(event.symbol));
        }

        if (filteredEvents.length === 0) {
            return (
                <Box
                    p={4}
                    textAlign="center"
                    color="text.secondary"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: "100%",
                        backgroundColor: '#f5f5f5'
                    }}
                >
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                        No Events Found
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {selectedContent
                            ? `No upcoming events available for ${selectedContent.nse || selectedContent.symbol}`
                            : newsFilter === 'POSITIONS'
                                ? 'No events found for portfolio symbols'
                                : 'Select a stock to filter events or view all upcoming events'}
                    </Typography>
                </Box>
            );
        }

        return (
            <Box sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
                <TableContainer
                    component={Paper}
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTable-root': {
                            flex: 1
                        }
                    }}
                >
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Symbol</TableCell>
                                <TableCell>Event</TableCell>
                                <TableCell>Purpose</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredEvents.map((event, index) => (
                                <TableRow
                                    key={`event-${index}`}
                                    sx={{
                                        backgroundColor: new Date(event.date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0) ? '#e3f2fd' : 'inherit'
                                    }}
                                >
                                    <TableCell>{formatDateTime(event.date, true)}</TableCell>
                                    <TableCell>{event.symbol}</TableCell>
                                    <TableCell sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                        {event.bm_desc}
                                    </TableCell>
                                    <TableCell>{event.purpose}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    };

    return (
        <Box width="37.5%" overflow="hidden" sx={{
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
        }}>
            <Box sx={{
                position: 'absolute',
                top: 8,
                right: 16,
                zIndex: 2
            }}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleLogout}
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.875rem'
                    }}
                >
                    Logout
                </Button>
            </Box>

            {/* Cash Positions Section */}
            <Box sx={{
                height: '30%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0
            }}>
                <Box sx={styles.positionHeader}>
          <span className="title">
            Cash Positions {selectedContent && `- ${selectedContent.nse || selectedContent.symbol}`}
          </span>
                </Box>
                <Box sx={{
                    flex: 1,
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    {renderPositions('cash')}
                </Box>
            </Box>

            {renderMissingPositionsAccounts()}

            {/* F&O Positions Section */}
            <Box sx={{
                height: '30%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0
            }}>
                <Box sx={styles.positionHeader}>
          <span className="title">
            F&O Positions {selectedContent && `- ${selectedContent.nse || selectedContent.symbol}`}
          </span>
                </Box>
                <Box sx={{
                    flex: 1,
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    {renderPositions('fno')}
                </Box>
            </Box>

            {/* Event Calendar Section */}
            <Box sx={{
                height: '40%',
                display: 'flex',
                flexDirection: 'column',
                minHeight: 0
            }}>
                <Box sx={styles.positionHeader}>
                    <span className="title">Event Calendar</span>
                </Box>
                <Box sx={{
                    flex: 1,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    {renderEventCalendar()}
                </Box>
            </Box>
        </Box>
    );
};

export default RightPanel;